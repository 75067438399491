/** 
 * Carousel img
*/
import LarbLettuce from "../img/recipes/1_TH_LarbLettuce.webp";
import LarbLettuce2 from "../img/recipes/1_TH_LarbLettuce2.webp";
import LarbLettuce3 from "../img/recipes/1_TH_LarbLettuce3.webp";
import spagettiBolognese from "../img/recipes/2_TH_SpaghettiBolognese.webp";
import spagettiBolognese2 from "../img/recipes/2_TH_SpaghettiBolognese2.webp";
import spagettiBolognese3 from "../img/recipes/2_TH_SpaghettiBolognese3.webp";
import Krapow from "../img/recipes/3_TH_PadKrapow.webp";
import Krapow2 from "../img/recipes/3_TH_PadKrapow2.webp";
import Krapow3 from "../img/recipes/3_TH_PadKrapow3.webp";
import Teriyaki from "../img/recipes/4_TH_GingerTeriyakiBowl.webp";
import Teriyaki2 from "../img/recipes/4_TH_GingerTeriyakiBowl2.webp";
import Teriyaki3 from "../img/recipes/4_TH_GingerTeriyakiBowl3.webp";
import SpringRolls from "../img/recipes/5_TH_SpringRolls.webp";
import SpringRolls2 from "../img/recipes/5_TH_SpringRolls2.webp";
import SpringRolls3 from "../img/recipes/5_TH_SpringRolls3.webp";
import KatsuSando from "../img/recipes/6_TH_CrispyNuggetKatsu.webp";
import KatsuSando2 from "../img/recipes/6_TH_CrispyNuggetKatsu2.webp";
import KatsuSando3 from "../img/recipes/6_TH_CrispyNuggetKatsu3.webp";
import HerbSalad from "../img/recipes/7_TH_HerbSalad.webp";
import HerbSalad2 from "../img/recipes/7_TH_HerbSalad2.webp";
import HerbSalad3 from "../img/recipes/7_TH_HerbSalad3.webp";
import KhaoJeeCanapes from "../img/recipes/8_TH_KhaoJeeCanapes.webp";
import KhaoJeeCanapes2 from "../img/recipes/8_TH_KhaoJeeCanapes2.webp";
import KhaoJeeCanapes3 from "../img/recipes/8_TH_KhaoJeeCanapes3.webp";
import SpicyPattiesMangoSalad from "../img/recipes/9_TH_TodMun.webp";
import SpicyPattiesMangoSalad2 from "../img/recipes/9_TH_TodMun2.webp";
import SpicyPattiesMangoSalad3 from "../img/recipes/9_TH_TodMun3.webp";
import ChickenWaffles from "../img/recipes/10_TH_No-ChickenWaffles.webp";
import ChickenWaffles2 from "../img/recipes/10_TH_No-ChickenWaffles2.webp";
import ChickenWaffles3 from "../img/recipes/10_TH_No-ChickenWaffles3.webp";

/** 
 * Products img
*/
import cookedMince from "../img/products/jseries/nacm.webp";
import crispyNuggets from "../img/products/snackseries/2nsscn1.webp";
import larbBites from "../img/products/snackseries/2nsslb1.webp";
import larbBalls from "../img/products/thaiseries/tsbl1.png";
import SpicyPatties from "../img/products/thaiseries/tssp1.png";
import OriginalFriedChicken from "../img/products/thaiseries/tsosfc1.png";

import {recipeIngredients, recipePreparations} from "../data/recipeIngredientsSteps";
export const recipeDetailData = [
    {
        id:0,
        title:"recipeDescription1.title",
        metaTitle:"recipeDescription1.metaTitle",
        metaDescription:"recipeDescription1.metaDescription",
        brand:"First Pride",
        type:"recipeDescription1.type",
        servings:"recipeDescription1.servings",
        recipeImage1:LarbLettuce,
        recipeImage1Alt:"recipeDescription1.recipeImage1Alt",
        recipeImage2:LarbLettuce2,
        recipeImage2Alt:"recipeDescription1.recipeImage2Alt",
        recipeImage3:LarbLettuce3,
        recipeImage3Alt:"recipeDescription1.recipeImage3Alt",
        description:"recipeDescription1.description",
        product: {
            title:'recipeMadeWith1',
            path:'/products/cooked_mince',
            img:cookedMince
        },
        ingredientsHeader: "recipeDescription1.ingredientsHeader",
        proceduresHeader: "recipeDescription1.proceduresHeader",
        ingredients:recipeIngredients[0],
        cookTime:"recipeDescription1.cookTime",
        procedures:recipePreparations[0]
    },
    {
        id:1,
        title:"recipeDescription2.title",
        metaTitle:"recipeDescription2.metaTitle",
        metaDescription:"recipeDescription2.metaDescription",
        brand:"First Pride",
        type:"recipeDescription2.type",
        servings:"recipeDescription2.servings",
        recipeImage1:spagettiBolognese,
        recipeImage1Alt:"recipeDescription2.recipeImage1Alt",
        recipeImage2:spagettiBolognese2,
        recipeImage2Alt:"recipeDescription2.recipeImage2Alt",
        recipeImage3:spagettiBolognese3,
        recipeImage3Alt:"recipeDescription2.recipeImage3Alt",
        description:"recipeDescription2.description",
        product: {
            title:'recipeMadeWith2',
            path:'/products/cooked_mince',
            img:cookedMince
        },
        ingredientsHeader: "recipeDescription2.ingredientsHeader",
        proceduresHeader: "recipeDescription2.proceduresHeader",
        ingredients:recipeIngredients[1],
        cookTime:"recipeDescription2.cookTime",
        procedures:recipePreparations[1]
    },
    {
        id:2,
        title:"recipeDescription3.title",
        metaTitle:"recipeDescription3.metaTitle",
        metaDescription:"recipeDescription3.metaDescription",
        brand:"First Pride",
        type:"recipeDescription3.type",
        servings:"recipeDescription3.servings",
        recipeImage1:Krapow,
        recipeImage1Alt:"recipeDescription3.recipeImage1Alt",
        recipeImage2:Krapow2,
        recipeImage2Alt:"recipeDescription3.recipeImage2Alt",
        recipeImage3:Krapow3,
        recipeImage3Alt:"recipeDescription3.recipeImage3Alt",
        description:"recipeDescription3.description",
        product: {
            title:'recipeMadeWith3',
            path:'/products/cooked_mince',
            img:cookedMince
        },
        ingredientsHeader: "recipeDescription3.ingredientsHeader",
        proceduresHeader: "recipeDescription3.proceduresHeader",
        ingredients:recipeIngredients[2],
        cookTime:"recipeDescription3.cookTime",
        procedures:recipePreparations[2]
    },
    {
        id:3,
        title:"recipeDescription4.title",
        metaTitle:"recipeDescription4.metaTitle",
        metaDescription:"recipeDescription4.metaDescription",
        brand:"First Pride",
        type:"recipeDescription4.type",
        servings:"recipeDescription4.servings",
        recipeImage1:Teriyaki,
        recipeImage1Alt:"recipeDescription4.recipeImage1Alt",
        recipeImage2:Teriyaki2,
        recipeImage2Alt:"recipeDescription4.recipeImage2Alt",
        recipeImage3:Teriyaki3,
        recipeImage3Alt:"recipeDescription4.recipeImage3Alt",
        description:"recipeDescription4.description",
        product: {
            title:'recipeMadeWith4',
            path:'/products/cooked_mince',
            img:cookedMince
        },
        ingredientsHeader: "recipeDescription4.ingredientsHeader",
        proceduresHeader: "recipeDescription4.proceduresHeader",
        ingredients:recipeIngredients[3],
        cookTime:"recipeDescription4.cookTime",
        procedures:recipePreparations[3]
    },
    {
        id:4,
        title:"recipeDescription5.title",
        metaTitle:"recipeDescription5.metaTitle",
        metaDescription:"recipeDescription5.metaDescription",
        brand:"First Pride",
        type:"recipeDescription5.type",
        servings:"recipeDescription5.servings",
        recipeImage1:SpringRolls,
        recipeImage1Alt:"recipeDescription5.recipeImage1Alt",
        recipeImage2:SpringRolls2,
        recipeImage2Alt:"recipeDescription5.recipeImage2Alt",
        recipeImage3:SpringRolls3,
        recipeImage3Alt:"recipeDescription5.recipeImage3Alt",
        description:"recipeDescription5.description",
        product: {
            title:'recipeMadeWith5',
            path:'/products/cooked_mince',
            img:cookedMince
        },
        ingredientsHeader: "recipeDescription5.ingredientsHeader",
        proceduresHeader: "recipeDescription5.proceduresHeader",
        ingredients:recipeIngredients[4],
        cookTime:"recipeDescription5.cookTime",
        procedures:recipePreparations[4]
    },
    {
        id:5,
        title:"recipeDescription6.title",
        metaTitle:"recipeDescription6.metaTitle",
        metaDescription:"recipeDescription6.metaDescription",
        brand:"First Pride",
        type:"recipeDescription6.type",
        servings:"recipeDescription6.servings",
        recipeImage1:KatsuSando,
        recipeImage1Alt:"recipeDescription6.recipeImage1Alt",
        recipeImage2:KatsuSando2,
        recipeImage2Alt:"recipeDescription6.recipeImage2Alt",
        recipeImage3:KatsuSando3,
        recipeImage3Alt:"recipeDescription6.recipeImage3Alt",
        description:"recipeDescription6.description",
        product: {
            title:'recipeMadeWith6',
            path:'/products/crispy_nuggets',
            img:crispyNuggets
        },
        ingredientsHeader: "recipeDescription6.ingredientsHeader",
        proceduresHeader: "recipeDescription6.proceduresHeader",
        ingredients:recipeIngredients[5],
        cookTime:"recipeDescription6.cookTime",
        procedures:recipePreparations[5]
    },
    {
        id:6,
        title:"recipeDescription7.title",
        metaTitle:"recipeDescription7.metaTitle",
        metaDescription:"recipeDescription7.metaDescription",
        brand:"First Pride",
        type:"recipeDescription7.type",
        servings:"recipeDescription7.servings",
        recipeImage1:HerbSalad,
        recipeImage1Alt:"recipeDescription7.recipeImage1Alt",
        recipeImage2:HerbSalad2,
        recipeImage2Alt:"recipeDescription7.recipeImage2Alt",
        recipeImage3:HerbSalad3,
        recipeImage3Alt:"recipeDescription7.recipeImage3Alt",
        description:"recipeDescription7.description",
        product: {
            title:'recipeMadeWith7',
            path:'/products/thai_spicy_bites',
            img:larbBites
        },
        ingredientsHeader: "recipeDescription7.ingredientsHeader",
        proceduresHeader: "recipeDescription7.proceduresHeader",
        ingredients:recipeIngredients[6],
        cookTime:"recipeDescription7.cookTime",
        procedures:recipePreparations[6]
    },
    {
        id:7,
        title:"recipeDescription8.title",
        metaTitle:"recipeDescription8.metaTitle",
        metaDescription:"recipeDescription8.metaDescription",
        brand:"First Pride",
        type:"recipeDescription8.type",
        servings:"recipeDescription8.servings",
        recipeImage1:KhaoJeeCanapes,
        recipeImage1Alt:"recipeDescription8.recipeImage1Alt",
        recipeImage2:KhaoJeeCanapes2,
        recipeImage2Alt:"recipeDescription8.recipeImage2Alt",
        recipeImage3:KhaoJeeCanapes3,
        recipeImage3Alt:"recipeDescription8.recipeImage3Alt",
        description:"recipeDescription8.description",
        product: {
            title:'recipeMadeWith8',
            path:'/products/thai_spicy_bites',
            img:larbBalls
        },
        ingredientsHeader: "recipeDescription8.ingredientsHeader",
        proceduresHeader: "recipeDescription8.proceduresHeader",
        ingredients:recipeIngredients[7],
        cookTime:"recipeDescription8.cookTime",
        procedures:recipePreparations[7]
    },
    {
        id:8,
        title:"recipeDescription9.title",
        metaTitle:"recipeDescription9.metaTitle",
        metaDescription:"recipeDescription9.metaDescription",
        brand:"First Pride",
        type:"recipeDescription9.type",
        servings:"recipeDescription9.servings",
        recipeImage1:SpicyPattiesMangoSalad,
        recipeImage1Alt:"recipeDescription9.recipeImage1Alt",
        recipeImage2:SpicyPattiesMangoSalad2,
        recipeImage2Alt:"recipeDescription9.recipeImage2Alt",
        recipeImage3:SpicyPattiesMangoSalad3,
        recipeImage3Alt:"recipeDescription9.recipeImage3Alt",
        description:"recipeDescription9.description",
        product: {
            title:'recipeMadeWith9',
            path:'/products/mini_thai_spicy_patties_with_lava_sauce',
            img:SpicyPatties
        },
        ingredientsHeader: "recipeDescription9.ingredientsHeader",
        proceduresHeader: "recipeDescription9.proceduresHeader",
        ingredients:recipeIngredients[8],
        cookTime:"recipeDescription9.cookTime",
        procedures:recipePreparations[8]
    },
    {
        id:9,
        title:"recipeDescription10.title",
        metaTitle:"recipeDescription10.metaTitle",
        metaDescription:"recipeDescription10.metaDescription",
        brand:"First Pride",
        type:"recipeDescription10.type",
        servings:"recipeDescription10.servings",
        recipeImage1:ChickenWaffles,
        recipeImage1Alt:"recipeDescription10.recipeImage1Alt",
        recipeImage2:ChickenWaffles2,
        recipeImage2Alt:"recipeDescription10.recipeImage2Alt",
        recipeImage3:ChickenWaffles3,
        recipeImage3Alt:"recipeDescription10.recipeImage3Alt",
        description:"recipeDescription10.description",
        product: {
            title:'recipeMadeWith10',
            path:'/products/original_style_fried_chicken',
            img:OriginalFriedChicken
        },
        ingredientsHeader: "recipeDescription10.ingredientsHeader",
        proceduresHeader: "recipeDescription10.proceduresHeader",
        ingredients:recipeIngredients[9],
        cookTime:"recipeDescription10.cookTime",
        procedures:recipePreparations[9]
    },
]
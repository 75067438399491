import React from "react";
import Layout from "../components/Layout";
import SmallButton from "../components/Button/SmallButton";
import OrangeButton from "../components/Button/OrangeButton";
import { Carousel } from "react-bootstrap";
import { recipeDetailData as data } from "../data/recipeDetail";
import InfoPanels from "../components/recipes/infoPanels";
import "../scss/recipedetail.scss";
import leaf from "../img/leafWatermark2.png";
import {recipeCards} from "../components/Card/recipeCards";
import RecipesSlider from "../components/RecipesSlider";
import GreenButton from "../components/Button/GreenButton";
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const RecipeDetail = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  const { i18n } = useI18next();
  const { t } = useTranslation();


  return (
    <Layout
      page={`recipeDetail`}
      title={t(data[i].metaTitle)}
      description={t(data[i].metaDescription)}
      navCustomBg={true}
    >
      <div className="recipeDetail">
        <img className="leafGraphic" src={leaf}/>
        <img className="leafGraphicLeft" src={leaf}/>
        
        <div className="productDetailContent">
          
          <div className="productDetails">
            <div className="productDesc">
              <h1 dangerouslySetInnerHTML={{ __html: t(data[i].title) }}></h1>
              <br />
              <div 
                className="recipeDetailpara recipeBlack"
                dangerouslySetInnerHTML={{ __html: t(data[i].description) }}
              />
              <InfoPanels classes="d-none d-lg-flex" type={t(data[i].type)} cookTime={t(data[i].cookTime)} servings={t(data[i].servings)}/>
            </div>
            
            <div className="productCarousel">
              <div>
                <Carousel className="productarouselWrapper" interval={null}>
                  <Carousel.Item className="recipeCarouselItem">
                    <img loading="lazy"
                      src={data[i].recipeImage1}
                      alt={t(data[i].recipeImage1Alt) !== "" ? t(data[i].recipeImage1Alt) : "hero1"}
                      className=" d-block pc-img fullCarousel"
                    />
                  </Carousel.Item>
                  <Carousel.Item className="productCarouselItem">
                    <img loading="lazy"
                        src={data[i].recipeImage2}
                        alt={t(data[i].recipeImage2Alt) !== "" ? t(data[i].recipeImage2Alt) : "hero2"}
                        className=" d-block pc-img fullCarousel"
                      />
                  </Carousel.Item>
                  <Carousel.Item className="productCarouselItem">
                    <img loading="lazy"
                      src={data[i].recipeImage3}
                      alt={t(data[i].recipeImage3Alt) !== "" ? t(data[i].recipeImage3Alt) : "hero3"}
                      className=" d-block pc-img fullCarousel"
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
              <InfoPanels classes="d-lg-none" type={t(data[i].type)} cookTime={t(data[i].cookTime)} servings={t(data[i].servings)}/>
            </div>
            
            
          </div>
          <div className="ingredientsDirections">
            <div className="ingredients">
              <div className="title">
                <h2>{t(data[i].ingredientsHeader)}</h2>
                <hr/>
              </div>
              
              {
                data[i].ingredients.map((ingredientRow, i) => {
                  if(ingredientRow.hasOwnProperty('sectionName')){
                    let { sectionName } = ingredientRow;

                    return (
                      <h2 key={t(sectionName)} className='sectionName'>{t(sectionName)}</h2>
                    );
                  } else {
                    let { ingredient, preparation, quantity, unit} = ingredientRow;

                    return (
                      <div key={t(ingredient)} className="ingredientRow">
                        <div className="ingredient">
                          <p className={`name ${i === 0 && 'firstIngredient'}`}>
                            <p dangerouslySetInnerHTML= {{ __html: t(ingredient)}}/>
                            {(t(preparation) !== '' && i > 0) ? ", " : " "}
                            {(t(preparation) !== '') && <small className="preparation">{t(preparation)}</small>}
                          </p>
                        </div>
    
                        {(t(quantity) !== t(unit)) ? <p className="quantity">{t(quantity)} {t(unit)}</p> : <p className="quantity">{t(quantity)}</p>}
                      </div>
                    );
                  }
              })
              }
            </div>

            <div className="directions">
              <div className="title">
                <h2>{t(data[i].proceduresHeader)}</h2>
                <hr/>
              </div>

              {
                data[i].procedures.map((procedure, i) => (
                  <h3 className="directionRow" key={`step${i+1}`}>
                    <p className="step">{i + 1}</p>
                    <div
                      className="procedure"
                      dangerouslySetInnerHTML={{ __html: t(procedure) }}
                    ></div>
                  </h3> 
                ))
              }
            </div>
          </div>
          <div className="madeWith">
            <div className="productDetailTitle">
              <h2>{t("recipeDetail.MadeWith")}</h2>
              <span>{t(data[i].product.title)}</span>
            </div>

            <img 
              src={data[i].product.img}
              alt="product"/>


            <div className="productDetailBtns">
              <GreenButton 
                  msg={t("recipeDetail.button1")}
                  icon='forward'
                  to={data[i].product.path}
                  target='_blank'/>

                <OrangeButton
                  msg={t("recipeDetail.button2")}
                  color="orange"
                  icon="cart"
                  to="https://redmart.lazada.sg/first-pride-123975828/?spm=a2o42.pdp_revamp.0.0.41142181cPkVQw&m=redmart&type=brand"
                  target="_blank"
                  yellow="#fff"/>
            </div>
          </div>
          

          <div className="relatedProd">
            <h2>{t("recipeDetail.YouMightAlsoLike")}</h2>

            <RecipesSlider recipes={recipeCards} currentRecipe={data[i].id}/>
          </div>

          <div className="backBtn">
            <SmallButton
              msg={t("recipeDetail.button3")}
              icon="backward"
              to="/recipes"
              color="white"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RecipeDetail;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer", "recipes"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const InfoPanels = (props) => {
    const {classes,type, cookTime, servings} = props;
    const { t } = useTranslation();
    return(
        <div className={`infoPanels ${classes}`}>
            <div className="infoPanel"> 
                <div className="infoPanelTag">{t("recipeDetail.panel1")}</div>
                <div className="infoPanelValue" >{type}</div>
            </div>
            <div className="infoPanel"> 
                <div className="infoPanelTag" >{t("recipeDetail.panel2")}</div>
                <div className="infoPanelValue" >{cookTime} {t("recipeDetail.panel2Mins")}</div>
            </div>
            <div className="infoPanel"> 
                <div className="infoPanelTag" >{t("recipeDetail.panel3")}</div>
                <div className="infoPanelValue" >{servings}</div>
            </div>
        </div>
    )
};

export default InfoPanels;
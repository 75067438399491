export const recipeIngredients= [
    [//recipe 1
        {
            ingredient: "recipeIngredients1.ingredient1",
            preparation: "recipeIngredients1.preparation1",
            unit: "recipeIngredients1.unit1",
            quantity: "recipeIngredients1.quantity1"
        },
        {
            ingredient: "recipeIngredients1.ingredient2",
            preparation: "recipeIngredients1.preparation2",
            unit: "recipeIngredients1.unit2",
            quantity: "recipeIngredients1.quantity2"
        },
        {
            ingredient: "recipeIngredients1.ingredient3",
            preparation: "recipeIngredients1.preparation3",
            unit: "recipeIngredients1.unit3",
            quantity: "recipeIngredients1.quantity3"
        },
        {
            ingredient: "recipeIngredients1.ingredient4",
            preparation: "recipeIngredients1.preparation4",
            unit: "recipeIngredients1.unit4",
            quantity: "recipeIngredients1.quantity4"
        },
        {
            ingredient: "recipeIngredients1.ingredient5",
            preparation: "recipeIngredients1.preparation5",
            unit: "recipeIngredients1.unit5",
            quantity: "recipeIngredients1.quantity5"
        },
        {
            ingredient: "recipeIngredients1.ingredient6",
            preparation: "recipeIngredients1.preparation6",
            unit: "recipeIngredients1.unit6",
            quantity: "recipeIngredients1.quantity6"
        },
        {
            ingredient: "recipeIngredients1.ingredient7",
            preparation: "recipeIngredients1.preparation7",
            unit: "recipeIngredients1.unit7",
            quantity: "recipeIngredients1.quantity7"
        },
        {
            ingredient: "recipeIngredients1.ingredient8",
            preparation: "recipeIngredients1.preparation8",
            unit: "recipeIngredients1.unit8",
            quantity: "recipeIngredients1.quantity8"
        },
        {
            ingredient: "recipeIngredients1.ingredient9",
            preparation: "recipeIngredients1.preparation9",
            unit: "recipeIngredients1.unit9",
            quantity: "recipeIngredients1.quantity9"
        },
        {
            ingredient: "recipeIngredients1.ingredient10",
            preparation: "recipeIngredients1.preparation10",
            unit: "recipeIngredients1.unit10",
            quantity: "recipeIngredients1.quantity10"
        }
        
    ],
    [//recipe2
        {
            ingredient: "recipeIngredients2.ingredient1",
            preparation: "recipeIngredients2.preparation1",
            unit: "recipeIngredients2.unit1",
            quantity: "recipeIngredients2.quantity1"
        },
        {
            ingredient: "recipeIngredients2.ingredient2",
            preparation: "recipeIngredients2.preparation2",
            unit: "recipeIngredients2.unit2",
            quantity: "recipeIngredients2.quantity2"
        },
        {
            ingredient: "recipeIngredients2.ingredient3",
            preparation: "recipeIngredients2.preparation3",
            unit: "recipeIngredients2.unit3",
            quantity: "recipeIngredients2.quantity3"
        },
        {
            ingredient: "recipeIngredients2.ingredient4",
            preparation: "recipeIngredients2.preparation4",
            unit: "recipeIngredients2.unit4",
            quantity: "recipeIngredients2.quantity4"
        },
        {
            ingredient: "recipeIngredients2.ingredient5",
            preparation: "recipeIngredients2.preparation5",
            unit: "recipeIngredients2.unit5",
            quantity: "recipeIngredients2.quantity5"
        },
        {
            ingredient: "recipeIngredients2.ingredient6",
            preparation: "recipeIngredients2.preparation6",
            unit: "recipeIngredients2.unit6",
            quantity: "recipeIngredients2.quantity6"
        },
        {
            ingredient: "recipeIngredients2.ingredient7",
            preparation: "recipeIngredients2.preparation7",
            unit: "recipeIngredients2.unit7",
            quantity: "recipeIngredients2.quantity7"
        },
        {
            ingredient: "recipeIngredients2.ingredient8",
            preparation: "recipeIngredients2.preparation8",
            unit: "recipeIngredients2.unit8",
            quantity: "recipeIngredients2.quantity8"
        },
        {
            ingredient: "recipeIngredients2.ingredient9",
            preparation: "recipeIngredients2.preparation9",
            unit: "recipeIngredients2.unit9",
            quantity: "recipeIngredients2.quantity9"
        },
        {
            ingredient: "recipeIngredients2.ingredient10",
            preparation: "recipeIngredients2.preparation10",
            unit: "recipeIngredients2.unit10",
            quantity: "recipeIngredients2.quantity10"
        }
    ],
    [//recipe 3
        {
            ingredient: "recipeIngredients3.ingredient1",
            preparation: "recipeIngredients3.preparation1",
            unit: "recipeIngredients3.unit1",
            quantity: "recipeIngredients3.quantity1"
        },
        {
            ingredient: "recipeIngredients3.ingredient2",
            preparation: "recipeIngredients3.preparation2",
            unit: "recipeIngredients3.unit2",
            quantity: "recipeIngredients3.quantity2"
        },
        {
            ingredient: "recipeIngredients3.ingredient3",
            preparation: "recipeIngredients3.preparation3",
            unit: "recipeIngredients3.unit3",
            quantity: "recipeIngredients3.quantity3"
        },
        {
            ingredient: "recipeIngredients3.ingredient4",
            preparation: "recipeIngredients3.preparation4",
            unit: "recipeIngredients3.unit4",
            quantity: "recipeIngredients3.quantity4"
        },
        {
            ingredient: "recipeIngredients3.ingredient5",
            preparation: "recipeIngredients3.preparation5",
            unit: "recipeIngredients3.unit5",
            quantity: "recipeIngredients3.quantity5"
        },
        {
            ingredient: "recipeIngredients3.ingredient6",
            preparation: "recipeIngredients3.preparation6",
            unit: "recipeIngredients3.unit6",
            quantity: "recipeIngredients3.quantity6"
        },
        {
            ingredient: "recipeIngredients3.ingredient7",
            preparation: "recipeIngredients3.preparation7",
            unit: "recipeIngredients3.unit7",
            quantity: "recipeIngredients3.quantity7"
        },
        {
            ingredient: "recipeIngredients3.ingredient8",
            preparation: "recipeIngredients3.preparation8",
            unit: "recipeIngredients3.unit8",
            quantity: "recipeIngredients3.quantity8"
        },
        {
            ingredient: "recipeIngredients3.ingredient9",
            preparation: "recipeIngredients3.preparation9",
            unit: "recipeIngredients3.unit9",
            quantity: "recipeIngredients3.quantity9"
        },
        {
            ingredient: "recipeIngredients3.ingredient10",
            preparation: "recipeIngredients3.preparation10",
            unit: "recipeIngredients3.unit10",
            quantity: "recipeIngredients3.quantity10"
        }
    ],
    [//recipe 4
        {
            ingredient: "recipeIngredients4.ingredient1",
            preparation: "recipeIngredients4.preparation1",
            unit: "recipeIngredients4.unit1",
            quantity: "recipeIngredients4.quantity1"
        },
        {
            ingredient: "recipeIngredients4.ingredient2",
            preparation: "recipeIngredients4.preparation2",
            unit: "recipeIngredients4.unit2",
            quantity: "recipeIngredients4.quantity2"
        },
        {
            ingredient: "recipeIngredients4.ingredient3",
            preparation: "recipeIngredients4.preparation3",
            unit: "recipeIngredients4.unit3",
            quantity: "recipeIngredients4.quantity3"
        },
        {
            ingredient: "recipeIngredients4.ingredient4",
            preparation: "recipeIngredients4.preparation4",
            unit: "recipeIngredients4.unit4",
            quantity: "recipeIngredients4.quantity4"
        },
        {
            ingredient: "recipeIngredients4.ingredient5",
            preparation: "recipeIngredients4.preparation5",
            unit: "recipeIngredients4.unit5",
            quantity: "recipeIngredients4.quantity5"
        },
        {
            ingredient: "recipeIngredients4.ingredient6",
            preparation: "recipeIngredients4.preparation6",
            unit: "recipeIngredients4.unit6",
            quantity: "recipeIngredients4.quantity6"
        },
        {
            ingredient: "recipeIngredients4.ingredient7",
            preparation: "recipeIngredients4.preparation7",
            unit: "recipeIngredients4.unit7",
            quantity: "recipeIngredients4.quantity7"
        },
        {
            ingredient: "recipeIngredients4.ingredient8",
            preparation: "recipeIngredients4.preparation8",
            unit: "recipeIngredients4.unit8",
            quantity: "recipeIngredients4.quantity8"
        }
    ],
    [//recipe 5
        {
            ingredient: "recipeIngredients5.ingredient1",
            preparation: "recipeIngredients5.preparation1",
            unit: "recipeIngredients5.unit1",
            quantity: "recipeIngredients5.quantity1"
        },
        {
            ingredient: "recipeIngredients5.ingredient2",
            preparation: "recipeIngredients5.preparation2",
            unit: "recipeIngredients5.unit2",
            quantity: "recipeIngredients5.quantity2"
        },
        {
            ingredient: "recipeIngredients5.ingredient3",
            preparation: "recipeIngredients5.preparation3",
            unit: "recipeIngredients5.unit3",
            quantity: "recipeIngredients5.quantity3"
        },
        {
            ingredient: "recipeIngredients5.ingredient4",
            preparation: "recipeIngredients5.preparation4",
            unit: "recipeIngredients5.unit4",
            quantity: "recipeIngredients5.quantity4"
        },
        {
            ingredient: "recipeIngredients5.ingredient5",
            preparation: "recipeIngredients5.preparation5",
            unit: "recipeIngredients5.unit5",
            quantity: "recipeIngredients5.quantity5"
        },
        {
            ingredient: "recipeIngredients5.ingredient6",
            preparation: "recipeIngredients5.preparation6",
            unit: "recipeIngredients5.unit6",
            quantity: "recipeIngredients5.quantity6"
        },
        {
            ingredient: "recipeIngredients5.ingredient7",
            preparation: "recipeIngredients5.preparation7",
            unit: "recipeIngredients5.unit7",
            quantity: "recipeIngredients5.quantity7"
        },
        {
            ingredient: "recipeIngredients5.ingredient8",
            preparation: "recipeIngredients5.preparation8",
            unit: "recipeIngredients5.unit8",
            quantity: "recipeIngredients5.quantity8"
        },
        {
            ingredient: "recipeIngredients5.ingredient9",
            preparation: "recipeIngredients5.preparation9",
            unit: "recipeIngredients5.unit9",
            quantity: "recipeIngredients5.quantity9"
        },
        {
            ingredient: "recipeIngredients5.ingredient10",
            preparation: "recipeIngredients5.preparation10",
            unit: "recipeIngredients5.unit10",
            quantity: "recipeIngredients5.quantity10"
        },
        {
            ingredient: "recipeIngredients5.ingredient11",
            preparation: "recipeIngredients5.preparation11",
            unit: "recipeIngredients5.unit11",
            quantity: "recipeIngredients5.quantity11"
        },
        {
            ingredient: "recipeIngredients5.ingredient12",
            preparation: "recipeIngredients5.preparation12",
            unit: "recipeIngredients5.unit12",
            quantity: "recipeIngredients5.quantity12"
        },
        {
            ingredient: "recipeIngredients5.ingredient13",
            preparation: "recipeIngredients5.preparation13",
            unit: "recipeIngredients5.unit13",
            quantity: "recipeIngredients5.quantity13"
        },
        {
            ingredient: "recipeIngredients5.ingredient14",
            preparation: "recipeIngredients5.preparation14",
            unit: "recipeIngredients5.unit14",
            quantity: "recipeIngredients5.quantity14"
        },
        {
            ingredient: "recipeIngredients5.ingredient15",
            preparation: "recipeIngredients5.preparation15",
            unit: "recipeIngredients5.unit15",
            quantity: "recipeIngredients5.quantity15"
        }
    ],
    [//recipe 6
        {
            ingredient: "recipeIngredients6.ingredient1",
            preparation: "recipeIngredients6.preparation1",
            unit: "recipeIngredients6.unit1",
            quantity: "recipeIngredients6.quantity1"
        },
        {
            ingredient: "recipeIngredients6.ingredient2",
            preparation: "recipeIngredients6.preparation2",
            unit: "recipeIngredients6.unit2",
            quantity: "recipeIngredients6.quantity2"
        },
        {
            ingredient: "recipeIngredients6.ingredient3",
            preparation: "recipeIngredients6.preparation3",
            unit: "recipeIngredients6.unit3",
            quantity: "recipeIngredients6.quantity3"
        },
        {
            ingredient: "recipeIngredients6.ingredient4",
            preparation: "recipeIngredients6.preparation4",
            unit: "recipeIngredients6.unit4",
            quantity: "recipeIngredients6.quantity4"
        },
        {
            ingredient: "recipeIngredients6.ingredient5",
            preparation: "recipeIngredients6.preparation5",
            unit: "recipeIngredients6.unit5",
            quantity: "recipeIngredients6.quantity5"
        },
        {
            ingredient: "recipeIngredients6.ingredient6",
            preparation: "recipeIngredients6.preparation6",
            unit: "recipeIngredients6.unit6",
            quantity: "recipeIngredients6.quantity6"
        },
        {
            ingredient: "recipeIngredients6.ingredient7",
            preparation: "recipeIngredients6.preparation7",
            unit: "recipeIngredients6.unit7",
            quantity: "recipeIngredients6.quantity7"
        }
    ],
    [//recipe 7
        {
            ingredient: "recipeIngredients7.ingredient1",
            preparation: "recipeIngredients7.preparation1",
            unit: "recipeIngredients7.unit1",
            quantity: "recipeIngredients7.quantity1"
        },
        {
            ingredient: "recipeIngredients7.ingredient2",
            preparation: "recipeIngredients7.preparation2",
            unit: "recipeIngredients7.unit2",
            quantity: "recipeIngredients7.quantity2"
        },
        {
            ingredient: "recipeIngredients7.ingredient3",
            preparation: "recipeIngredients7.preparation3",
            unit: "recipeIngredients7.unit3",
            quantity: "recipeIngredients7.quantity3"
        },
        {
            ingredient: "recipeIngredients7.ingredient4",
            preparation: "recipeIngredients7.preparation4",
            unit: "recipeIngredients7.unit4",
            quantity: "recipeIngredients7.quantity4"
        },
        {
            ingredient: "recipeIngredients7.ingredient5",
            preparation: "recipeIngredients7.preparation5",
            unit: "recipeIngredients7.unit5",
            quantity: "recipeIngredients7.quantity5"
        },
        {
            ingredient: "recipeIngredients7.ingredient6",
            preparation: "recipeIngredients7.preparation6",
            unit: "recipeIngredients7.unit6",
            quantity: "recipeIngredients7.quantity6"
        },
        {
            ingredient: "recipeIngredients7.ingredient7",
            preparation: "recipeIngredients7.preparation7",
            unit: "recipeIngredients7.unit7",
            quantity: "recipeIngredients7.quantity7"
        },
        {
            ingredient: "recipeIngredients7.ingredient8",
            preparation: "recipeIngredients7.preparation8",
            unit: "recipeIngredients7.unit8",
            quantity: "recipeIngredients7.quantity8"
        },
        {
            ingredient: "recipeIngredients7.ingredient9",
            preparation: "recipeIngredients7.preparation9",
            unit: "recipeIngredients7.unit9",
            quantity: "recipeIngredients7.quantity9"
        },
        {
            ingredient: "recipeIngredients7.ingredient10",
            preparation: "recipeIngredients7.preparation10",
            unit: "recipeIngredients7.unit10",
            quantity: "recipeIngredients7.quantity10"
        },
        {
            ingredient: "recipeIngredients7.ingredient11",
            preparation: "recipeIngredients7.preparation11",
            unit: "recipeIngredients7.unit11",
            quantity: "recipeIngredients7.quantity11"
        },
        {
            ingredient: "recipeIngredients7.ingredient12",
            preparation: "recipeIngredients7.preparation12",
            unit: "recipeIngredients7.unit12",
            quantity: "recipeIngredients7.quantity12"
        },
        {
            sectionName: "recipeIngredients7.sectionName1"
        },
        {
            ingredient: "recipeIngredients7.ingredient13",
            preparation: "recipeIngredients7.preparation13",
            unit: "recipeIngredients7.unit13",
            quantity: "recipeIngredients7.quantity13",
            sectionIngredient: "recipeIngredients7.sectionIngredient1"
        },
        {
            ingredient: "recipeIngredients7.ingredient14",
            preparation: "recipeIngredients7.preparation14",
            unit: "recipeIngredients7.unit14",
            quantity: "recipeIngredients7.quantity14",
            sectionIngredient: "recipeIngredients7.sectionIngredient2"
        },
        {
            ingredient: "recipeIngredients7.ingredient15",
            preparation: "recipeIngredients7.preparation15",
            unit: "recipeIngredients7.unit15",
            quantity: "recipeIngredients7.quantity15",
            sectionIngredient: "recipeIngredients7.sectionIngredient3"
        },
        {
            ingredient: "recipeIngredients7.ingredient16",
            preparation: "recipeIngredients7.preparation16",
            unit: "recipeIngredients7.unit16",
            quantity: "recipeIngredients7.quantity16",
            sectionIngredient: "recipeIngredients7.sectionIngredient4"
        },
        {
            ingredient: "recipeIngredients7.ingredient17",
            preparation: "recipeIngredients7.preparation17",
            unit: "recipeIngredients7.unit17",
            quantity: "recipeIngredients7.quantity17",
            sectionIngredient: "recipeIngredients7.sectionIngredient5"
        },
        {
            ingredient: "recipeIngredients7.ingredient18",
            preparation: "recipeIngredients7.preparation18",
            unit: "recipeIngredients7.unit18",
            quantity: "recipeIngredients7.quantity18",
            sectionIngredient: "recipeIngredients7.sectionIngredient6"
        },
        {
            ingredient: "recipeIngredients7.ingredient19",
            preparation: "recipeIngredients7.preparation19",
            unit: "recipeIngredients7.unit19",
            quantity: "recipeIngredients7.quantity19",
            sectionIngredient: "recipeIngredients7.sectionIngredient7"
        },
        {
            ingredient: "recipeIngredients7.ingredient20",
            preparation: "recipeIngredients7.preparation20",
            unit: "recipeIngredients7.unit20",
            quantity: "recipeIngredients7.quantity20",
            sectionIngredient: "recipeIngredients7.sectionIngredient8"
        }
    ],
    [//recipe 8
        {
            ingredient: "recipeIngredients8.ingredient1",
            preparation: "recipeIngredients8.preparation1",
            unit: "recipeIngredients8.unit1",
            quantity: "recipeIngredients8.quantity1"
        },
        {
            ingredient: "recipeIngredients8.ingredient2",
            preparation: "recipeIngredients8.preparation2",
            unit: "recipeIngredients8.unit2",
            quantity: "recipeIngredients8.quantity2"
        },
        {
            ingredient: "recipeIngredients8.ingredient3",
            preparation: "recipeIngredients8.preparation3",
            unit: "recipeIngredients8.unit3",
            quantity: "recipeIngredients8.quantity3"
        },
        {
            ingredient: "recipeIngredients8.ingredient4",
            preparation: "recipeIngredients8.preparation4",
            unit: "recipeIngredients8.unit4",
            quantity: "recipeIngredients8.quantity4"
        },
        {
            ingredient: "recipeIngredients8.ingredient5",
            preparation: "recipeIngredients8.preparation5",
            unit: "recipeIngredients8.unit5",
            quantity: "recipeIngredients8.quantity5"
        },
        {
            ingredient: "recipeIngredients8.ingredient6",
            preparation: "recipeIngredients8.preparation6",
            unit: "recipeIngredients8.unit6",
            quantity: "recipeIngredients8.quantity6"
        },
        {
            ingredient: "recipeIngredients8.ingredient7",
            preparation: "recipeIngredients8.preparation7",
            unit: "recipeIngredients8.unit7",
            quantity: "recipeIngredients8.quantity7"
        },
        {
            ingredient: "recipeIngredients8.ingredient8",
            preparation: "recipeIngredients8.preparation8",
            unit: "recipeIngredients8.unit8",
            quantity: "recipeIngredients8.quantity8"
        },
        {
            ingredient: "recipeIngredients8.ingredient9",
            preparation: "recipeIngredients8.preparation9",
            unit: "recipeIngredients8.unit9",
            quantity: "recipeIngredients8.quantity9"
        },
        {
            ingredient: "recipeIngredients8.ingredient10",
            preparation: "recipeIngredients8.preparation10",
            unit: "recipeIngredients8.unit10",
            quantity: "recipeIngredients8.quantity10"
        },
        {
            ingredient: "recipeIngredients8.ingredient11",
            preparation: "recipeIngredients8.preparation11",
            unit: "recipeIngredients8.unit11",
            quantity: "recipeIngredients8.quantity11"
        },
        {
            ingredient: "recipeIngredients8.ingredient12",
            preparation: "recipeIngredients8.preparation12",
            unit: "recipeIngredients8.unit12",
            quantity: "recipeIngredients8.quantity12"
        },
        {
            ingredient: "recipeIngredients8.ingredient13",
            preparation: "recipeIngredients8.preparation13",
            unit: "recipeIngredients8.unit13",
            quantity: "recipeIngredients8.quantity13"
        }
    ],
    [//recipe 9
        {
            ingredient: "recipeIngredients9.ingredient1",
            preparation: "recipeIngredients9.preparation1",
            unit: "recipeIngredients9.unit1",
            quantity: "recipeIngredients9.quantity1"
        },
        {
            ingredient: "recipeIngredients9.ingredient2",
            preparation: "recipeIngredients9.preparation2",
            unit: "recipeIngredients9.unit2",
            quantity: "recipeIngredients9.quantity2"
        },
        {
            ingredient: "recipeIngredients9.ingredient3",
            preparation: "recipeIngredients9.preparation3",
            unit: "recipeIngredients9.unit3",
            quantity: "recipeIngredients9.quantity3"
        },
        {
            ingredient: "recipeIngredients9.ingredient4",
            preparation: "recipeIngredients9.preparation4",
            unit: "recipeIngredients9.unit4",
            quantity: "recipeIngredients9.quantity4"
        },
        {
            ingredient: "recipeIngredients9.ingredient5",
            preparation: "recipeIngredients9.preparation5",
            unit: "recipeIngredients9.unit5",
            quantity: "recipeIngredients9.quantity5"
        },
        {
            ingredient: "recipeIngredients9.ingredient6",
            preparation: "recipeIngredients9.preparation6",
            unit: "recipeIngredients9.unit6",
            quantity: "recipeIngredients9.quantity6"
        },
        {
            ingredient: "recipeIngredients9.ingredient7",
            preparation: "recipeIngredients9.preparation7",
            unit: "recipeIngredients9.unit7",
            quantity: "recipeIngredients9.quantity7"
        },
        {
            ingredient: "recipeIngredients9.ingredient8",
            preparation: "recipeIngredients9.preparation8",
            unit: "recipeIngredients9.unit8",
            quantity: "recipeIngredients9.quantity8"
        },
        {
            ingredient: "recipeIngredients9.ingredient9",
            preparation: "recipeIngredients9.preparation9",
            unit: "recipeIngredients9.unit9",
            quantity: "recipeIngredients9.quantity9"
        },
        {
            ingredient: "recipeIngredients9.ingredient10",
            preparation: "recipeIngredients9.preparation10",
            unit: "recipeIngredients9.unit10",
            quantity: "recipeIngredients9.quantity10"
        },
        {
            ingredient: "recipeIngredients9.ingredient11",
            preparation: "recipeIngredients9.preparation11",
            unit: "recipeIngredients9.unit11",
            quantity: "recipeIngredients9.quantity11"
        },
        {
            ingredient: "recipeIngredients9.ingredient12",
            preparation: "recipeIngredients9.preparation12",
            unit: "recipeIngredients9.unit12",
            quantity: "recipeIngredients9.quantity12"
        }
    ],
    [//recipe 10
        {
            ingredient: "recipeIngredients10.ingredient1",
            preparation: "recipeIngredients10.preparation1",
            unit: "recipeIngredients10.unit1",
            quantity: "recipeIngredients10.quantity1"
        },
        {
            ingredient: "recipeIngredients10.ingredient2",
            preparation: "recipeIngredients10.preparation2",
            unit: "recipeIngredients10.unit2",
            quantity: "recipeIngredients10.quantity2"
        },
        {
            sectionName: "recipeIngredients10.sectionName1"
        },
        {
            ingredient: "recipeIngredients10.ingredient3",
            preparation: "recipeIngredients10.preparation3",
            unit: "recipeIngredients10.unit3",
            quantity: "recipeIngredients10.quantity3",
            sectionIngredient: "recipeIngredients10.sectionIngredient1"
        },
        {
            ingredient: "recipeIngredients10.ingredient4",
            preparation: "recipeIngredients10.preparation4",
            unit: "recipeIngredients10.unit4",
            quantity: "recipeIngredients10.quantity4",
            sectionIngredient: "recipeIngredients10.sectionIngredient2"
        },
        {
            ingredient: "recipeIngredients10.ingredient5",
            preparation: "recipeIngredients10.preparation5",
            unit: "recipeIngredients10.unit5",
            quantity: "recipeIngredients10.quantity5",
            sectionIngredient: "recipeIngredients10.sectionIngredient3"
        },
        {
            ingredient: "recipeIngredients10.ingredient6",
            preparation: "recipeIngredients10.preparation6",
            unit: "recipeIngredients10.unit6",
            quantity: "recipeIngredients10.quantity6",
            sectionIngredient: "recipeIngredients10.sectionIngredient4"
        },
        {
            ingredient: "recipeIngredients10.ingredient7",
            preparation: "recipeIngredients10.preparation7",
            unit: "recipeIngredients10.unit7",
            quantity: "recipeIngredients10.quantity7"
        }
    ]
];

export const recipePreparations= [
    [//recipe 1
        "recipeProcedures1.procedureStep1",
        "recipeProcedures1.procedureStep2",
        "recipeProcedures1.procedureStep3",
        "recipeProcedures1.procedureStep4",
        "recipeProcedures1.procedureStep5",
        "recipeProcedures1.procedureStep6",
        "recipeProcedures1.procedureStep7"
    ],
    [//recipe 2
        "recipeProcedures2.procedureStep1",
        "recipeProcedures2.procedureStep2",
        "recipeProcedures2.procedureStep3",
        "recipeProcedures2.procedureStep4",
        "recipeProcedures2.procedureStep5",
        "recipeProcedures2.procedureStep6",
        "recipeProcedures2.procedureStep7"
    ],
    [//recipe 3
        "recipeProcedures3.procedureStep1",
        "recipeProcedures3.procedureStep2",
        "recipeProcedures3.procedureStep3",
        "recipeProcedures3.procedureStep4",
        "recipeProcedures3.procedureStep5",
        "recipeProcedures3.procedureStep6",
        "recipeProcedures3.procedureStep7"
    ],
    [//recipe 4
        "recipeProcedures4.procedureStep1",
        "recipeProcedures4.procedureStep2",
        "recipeProcedures4.procedureStep3",
        "recipeProcedures4.procedureStep4",
        "recipeProcedures4.procedureStep5",
        "recipeProcedures4.procedureStep6"
    ],
    [//recipe 5
        "recipeProcedures5.procedureStep1",
        "recipeProcedures5.procedureStep2",
        "recipeProcedures5.procedureStep3",
        "recipeProcedures5.procedureStep4",
        "recipeProcedures5.procedureStep5",
        "recipeProcedures5.procedureStep6"
    ],
    [//recipe 6
        "recipeProcedures6.procedureStep1",
        "recipeProcedures6.procedureStep2",
        "recipeProcedures6.procedureStep3",
        "recipeProcedures6.procedureStep4",
        "recipeProcedures6.procedureStep5",
        "recipeProcedures6.procedureStep6",
        "recipeProcedures6.procedureStep7"
    ],
    [//recipe 7
        "recipeProcedures7.procedureStep1",
        "recipeProcedures7.procedureStep2",
        "recipeProcedures7.procedureStep3",
        "recipeProcedures7.procedureStep4"
    ],
    [//recipe 8
        "recipeProcedures8.procedureStep1",
        "recipeProcedures8.procedureStep2",
        "recipeProcedures8.procedureStep3",
        "recipeProcedures8.procedureStep4",
        "recipeProcedures8.procedureStep5",
        "recipeProcedures8.procedureStep6",
        "recipeProcedures8.procedureStep7",
        "recipeProcedures8.procedureStep8",
        "recipeProcedures8.procedureStep9",
        "recipeProcedures8.procedureStep10"
    ],
    [//recipe 9
        "recipeProcedures9.procedureStep1",
        "recipeProcedures9.procedureStep2",
        "recipeProcedures9.procedureStep3",
        "recipeProcedures9.procedureStep4",
        "recipeProcedures9.procedureStep5"
    ],
    [//recipe 10
        "recipeProcedures10.procedureStep1",
        "recipeProcedures10.procedureStep2",
        "recipeProcedures10.procedureStep3",
        "recipeProcedures10.procedureStep4",
        "recipeProcedures10.procedureStep5",
        "recipeProcedures10.procedureStep6"
    ]
];
import styled from "styled-components";
import React from "react";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import { BsCart } from "react-icons/bs";
import { SmallShopButton, SmallIconStyle, MsgWrapper } from "./styles";

const ForwardIcon = styled(IoArrowForward)``;
const BackwardIcon = styled(IoArrowBack)``;
const CartIcon = styled(BsCart)``;

const SmallButton = (props) => {
    return (
        <SmallShopButton to={props.to} target={props.target}>
            <SmallIconStyle>{props.icon === "cart" ? <CartIcon /> : props.icon === "backward" ? <BackwardIcon /> : <ForwardIcon />}</SmallIconStyle>
            <MsgWrapper>{props.msg}</MsgWrapper>
        </SmallShopButton>
    );
};
export default SmallButton;
